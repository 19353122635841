import { parseExpression } from '@/helpers/ExpressionParser';

export default new class NumberFormatter {

    parse(data, values) {
        const { value: parsedNumber } = parseExpression(data.data.number, values, 'strip');
        const { value: parsedDecimalPlaces } = parseExpression(data.data.decimalPlaces, values, 'strip');

        let format = 'de-DE';
        switch (data.data.format) {
        case 'United States':
            format = 'en-US';
            break;
        }

        const formatter = new Intl.NumberFormat(format, {
            style: data.data.currency.show ? 'currency' : 'decimal',
            currency: data.data.currency.type,
            minimumFractionDigits: parseInt(parsedDecimalPlaces),
            maximumFractionDigits: parseInt(parsedDecimalPlaces),
        });

        return {
            value: formatter.format(parsedNumber),
            isPlainText: true
        };
    }
};
