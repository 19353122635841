const moment = require('moment-timezone');
import { parseExpression } from '@/helpers/ExpressionParser';

export default new class  Now {

    parse(data, values) {
        let value = '';
        if (data.data.format === 'Custom') {
            value = moment.tz(data.data.timezone).format(
                (parseExpression(data.data.customFormat, values, 'strip')).value
            );
        } else {
            value = moment().tz(data.data.timezone).format(data.data.format);
        }
        return {
            value,
            isPlainText: true
        };
    }
};
