<template>
  <div class="max-w-full">
    <div class="is-flex is-justify-content-flex-end">
      <TableOptionsDropdown 
        v-if="field.properties.basic.tableIsExportable"
        @export="exportAsCsv()" 
      />
    </div>

    <b-table
      ref="dataTableElement"
      :data="tableData"
      :paginated="field.properties.basic.pagination > 0"
      :per-page="field.properties.basic.pagination"
      :backend-pagination="customPagination"
      :total="totalRecords"
      pagination-size="is-small"
      class="data-table"
      @page-change="handlePageChange"
      @click="handleClick($event)"
    >
      <template v-for="(column, cIndex) in field.properties.basic.headers">
        <b-table-column
          v-if="column"
          :key="column.id"
          v-bind="column"
        >
          <template #header>
            <div class="is-flex is-justify-content-space-between">
              <div class="is-flex is-align-items-center">
                <span class="column-heading">
                  {{ column.label }}
                </span>
                <b-icon
                  v-if="field.properties.basic.tableIsSortable"
                  size="is-small"
                  :icon="sortedColumn === column.field && dataTableElement ? (dataTableElement.isAsc ? 'arrow-up' : 'arrow-down') : 'arrow-down'"
                  @click.native="handleSorting(column, { sortOrder: dataTableElement.isAsc ? 'desc' : 'asc' })"
                />
              </div>
              <ColumnFiltersDropdown
                v-if="field.properties.basic.tableIsFilterable || field.properties.basic.tableIsSortable"
                :filter-icon="getFilterIcon(cIndex)"
                :column-data="getColumnData(column.label)"
                :column-filter="columnFilters[cIndex]"
                :column-key="getColumnKeyByLabel(column.label)"
                :pagination-metadata="paginationMetadata"
                :custom-pagination="customPagination"
                :is-filterable="field.properties.basic.tableIsFilterable"
                :is-sortable="field.properties.basic.tableIsSortable"
                @sort="handleSorting(column, $event)"
                @filter="handleFiltering(cIndex, $event)"
              />
            </div>
          </template>
          <template #default="slotProps">
            {{ slotProps.row[column.field] }}
          </template>
        </b-table-column>
      </template>
    </b-table>
  </div>
</template>

<script >
import { ref, computed, onMounted } from '@vue/composition-api';
import xlsx from 'json-as-xlsx';
import { cloneDeep } from 'lodash';
import ColumnFiltersDropdown from './ColumnFiltersDropdown.vue';
import TableOptionsDropdown from './TableOptionsDropdown.vue';
import FormRenderer from '@/helpers/FormRenderer';
import { extractReferenceId, extractReferenceIdFromFormula } from '@/helpers/ExpressionParser';
import { useApplicationStore } from '@/store/applicationStore';
import { useVariableStore } from '@/store/variableStore';
import { fetchRecordsService } from '@/services/database-service/recordRequests';
import { delay } from '@/helpers/Utils';
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  },
  values: {
    type: Object,
    required: true
  },
  fieldEvents: {
    type: Object,
    default: () => ({})
  },
  customEventHandler: {
    type: Function,
    default: () => {}
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const applicationStore = useApplicationStore();
  const variableStore = useVariableStore();
  const props = __props;
  const emit = __ctx.emit;
  const sortedColumn = ref('');
  const dataTableElement = ref(null);
  const columnFilters = ref([]);

  // Pagination variables
  const customPagination = ref(false);
  const paginationMetadata = ref({});
  const currentPage = ref(1);
  const filterRecords = ref({});
  const records = ref([]);
  const exportAsCsv = () => {
    const field = cloneDeep(props.field);
    if (field.properties.basic.exportAllColumns) {
      // handle extracting values from all columns
      let tableReference = extractReferenceId(field.properties.basic.columns[0].value);
      tableReference = tableReference.split('-').slice(0, 5).join('-');
      // generate mapping for all columns of table
      const allColumns = Object.keys(props.values).filter(key => key.includes(tableReference)).map(key => ({
        label: key.replace(`${tableReference}-`, ''),
        value: `<p><span class="mention" data-mention-id="${key}"></span></p>`,
        visible: true
      }));
      field.properties.basic.columns = allColumns;
      // let Form Renderer populate table data for all columns
      FormRenderer.renderForm(field, props.values);
    }
    const columns = field.properties.basic.headers.map((header, index) => ({
      label: header.label,
      value: `column_${index}` // create unique column values (these will be used for referencing values from each table row by json-as-xlsx plugin)
    }));
    const columnsMap = columns.reduce((result, currentColumn) => {
      result[currentColumn.label] = currentColumn.value;
      return result;
    }, {});
    const tableMappedData = field.properties.basic.data.reduce((result, currentRow) => {
      const key = currentRow.ID || currentRow.id;
      // currentRow object have keys according to column labels, map them to column values instead
      let mappedRow = {};
      Object.entries(currentRow || {}).forEach(([rowKey, rowValue]) => {
        const columnValueKey = columnsMap[rowKey];
        if (columnValueKey) {
          mappedRow[columnValueKey] = rowValue;
        }
      });
      result[key] = mappedRow;
      return result;
    }, {});

    // exported data as per applied column filters (if any)
    const exportedData = dataTableElement.value.newData.map(row => tableMappedData[row.ID || row.id] || null).filter(row => row);
    let data = [{
      sheet: 'Data Table',
      columns,
      content: exportedData
    }];
    let settings = {
      fileName: 'Data-Spreadsheet',
      extraLength: 3,
      writeOptions: {}
    };
    xlsx(data, settings);
  };
  const handleClick = async evt => {
    if (props.customEventHandler) {
      await props.customEventHandler();
    }
    emit('update', evt);
  };
  const tableData = computed(() => {
    let data = cloneDeep(props.field.properties.basic.data);
    if (!customPagination.value) {
      columnFilters.value.forEach(columnFilter => {
        // filter by value
        if (columnFilter?.text?.keywords?.length) {
          data = data?.filter(item => {
            const itemValue = item[columnFilter.columnId]?.toString().toLowerCase();
            const keywords = typeof columnFilter.text.keywords === 'string' ? [columnFilter.text.keywords] : columnFilter.text.keywords;
            if (keywords.includes('(Leer)')) {
              return keywords.some(keyword => itemValue.includes(keyword.toLowerCase())) || (typeof item[columnFilter.columnId] === 'string' ? item[columnFilter.columnId]?.trim() === '' : !item[columnFilter.columnId]);
            } else {
              return keywords.some(keyword => itemValue.includes(keyword.toLowerCase())) && (typeof item[columnFilter.columnId] === 'string' ? item[columnFilter.columnId]?.trim() !== '' : !item[columnFilter.columnId]);
            }
          });
        }
        // filter by conditions
        data = filterByConditions(columnFilter, data);
      });
    }
    return data;
  });
  const totalRecords = computed(() => paginationMetadata.value?.total_count || getColumnData(props.field.properties.basic?.headers?.[0]?.label)?.length);
  const getFilterIcon = index => {
    return columnFilters.value[index]?.text?.keywords?.length || columnFilters.value[index]?.condition?.value ? 'filter' : 'filter-variant';
  };
  const filterByConditions = (columnFilter, data) => {
    // columnFilter by date
    if (columnFilter?.condition?.type === 'date') {
      if (columnFilter.condition.subType && columnFilter.condition.value) {
        return data.filter(item => {
          const fieldValue = item[columnFilter.columnId];
          if (!fieldValue) {
            return false;
          }
          const fieldDate = new Date(fieldValue);
          const conditionDate = new Date(columnFilter.condition.value);
          const subConditionMapper = {
            'before': () => fieldDate <= conditionDate,
            'after': () => fieldDate >= conditionDate,
            'exactly': () => isSameDate(fieldDate, conditionDate)
          };
          const subCondition = subConditionMapper[columnFilter.condition.subType];
          if (subCondition) {
            return subCondition();
          }
          return true;
        });
      }
    }
    return data;
  };
  const isSameDate = (date1, date2) => {
    return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
  };
  const handleFiltering = async (index, filter, defaultFilters = null) => {
    const tempFilters = cloneDeep(columnFilters.value);
    if (defaultFilters?.length) {
      tempFilters.forEach((tempFilter, index) => {
        const defaultFilter = defaultFilters.find(filter => filter.label === tempFilter.columnId);
        if (defaultFilter && defaultFilter.keywords?.length) {
          tempFilters[index].text.keywords = defaultFilter.keywords;
        }
      });
    } else {
      const columnId = tempFilters[index]?.columnId;
      tempFilters[index] = filter;
      if (!tempFilters[index].columnId && columnId) {
        tempFilters[index].columnId = columnId;
      }
    }
    columnFilters.value = tempFilters;
    if (customPagination.value) {
      const columns = filterRecords.value.columns || {};
      const date_filter = filterRecords.value.date_filter || {};
      for (let filter of tempFilters) {
        const columnKey = getColumnKeyByLabel(filter.columnId);
        if (columnKey) {
          // Text filtering
          if (filter.text.keywords !== undefined) {
            if (filter.text.keywords.length === 0 && columns[columnKey] !== undefined) {
              delete columns[columnKey];
            } else if (filter.text.keywords.length) {
              columns[columnKey] = filter.text.keywords;
            }
          }

          // Date filtering
          if (filter.condition.type === 'none' && date_filter[columnKey] !== undefined) {
            delete date_filter[columnKey];
          } else if (filter.condition.type === 'date') {
            date_filter[columnKey] = {
              date: filter.condition.value,
              condition: filter.condition.subType
            };
          }
        }
      }
      filterRecords.value.date_filter = date_filter;
      filterRecords.value.columns = columns;
      await fetchRecords();
    }
  };
  const backendSort = async columns => {
    filterRecords.value = {
      columns: filterRecords.value.columns,
      sort_by: {},
      date_filter: filterRecords.value.date_filter
    };
    const validColumns = columns.filter(column => column.label);
    validColumns.forEach(column => {
      let column_key = getColumnKeyByLabel(column.label);
      if (column_key) {
        filterRecords.value.sort_by[column_key] = column.direction === 'ascending' || column.direction === 'asc' ? 'asc' : 'desc';
      }
    });
    await fetchRecords();
  };
  const handleSorting = async (column, payload) => {
    const {
      sortOrder
    } = payload;
    sortedColumn.value = column.field;
    // accessing internal properties of b-table via template ref
    dataTableElement.value.isAsc = sortOrder === 'asc';
    if (customPagination.value) {
      await backendSort([{
        label: column.label,
        direction: sortOrder
      }]);
    } else {
      dataTableElement.value.doSortSingleColumn({
        ...column,
        sortable: true
      });
    }
  };
  const getColumnData = columnId => props.field.properties.basic.data.map(row => row[columnId]);
  const handlePageChange = async page => {
    currentPage.value = page;
    if (customPagination.value) {
      await fetchRecords();
    }
  };
  const fetchRecords = async () => {
    try {
      const response = await fetchRecordsService({
        query: {
          database: paginationMetadata.value.query.database,
          table: paginationMetadata.value.query.table,
          condition: paginationMetadata.value.query.condition,
          filter: filterRecords.value,
          page: currentPage.value,
          limit: props.field.properties.basic.pagination || 10
        }
      });
      paginationMetadata.value.total_count = response.data.total_count;
      let updatedTableValues = response.data.data.reduce((result, record) => {
        Object.entries(record).forEach(([key, value]) => {
          const recordKey = `${paginationMetadata.value.databaseNodeId}-${key}`;
          if (!result[recordKey]) {
            result[recordKey] = [];
          }
          result[recordKey].push(value);
        });
        return result;
      }, {});
      Object.entries(updatedTableValues).forEach(([columnKey, columnValues]) => {
        columnValues = JSON.stringify(columnValues || []);
        columnValues = columnValues.replace('[', '{').replace(']', '}');
        variableStore.updateVariableById({
          type: 'variable',
          value: columnKey
        }, {
          value: columnValues
        });
      });
      const field = cloneDeep(props.field);
      // let form renderer populate the updated values in data table 
      await FormRenderer.renderForm(field, variableStore.variablesValues);
    } catch (err) {
      console.error(err);
    }
  };
  const getColumnReferenceByValue = label => {
    const columnRecord = props.field.properties.basic.columns.find(record => record.label === label);
    if (!columnRecord?.value) return null;
    let referenceId = extractReferenceId(columnRecord.value);
    if (!referenceId) referenceId = extractReferenceIdFromFormula(columnRecord.value);
    return referenceId;
  };
  const getColumnKeyByLabel = label => {
    let column_key;
    let column_reference = getColumnReferenceByValue(label);
    if (!column_reference) return null;
    let splitElements = column_reference.split('-');
    let lastPart;
    if (splitElements.length > 10) {
      lastPart = splitElements.slice(10);
    } else {
      lastPart = splitElements.slice(5);
    }
    if (lastPart.length > 1) {
      column_key = lastPart.join('-');
    } else {
      column_key = lastPart[0];
    }
    return column_key;
  };
  const defaultSortingAndFiltering = async () => {
    let {
      headers,
      defaultSortingColumn,
      hasDefaultSorting,
      sortingOrder,
      defaultSortingColumns,
      hasDefaultFiltering,
      defaultFilteringColumns
    } = props.field.properties.basic;
    let tableReference = extractReferenceId(props.field.properties.basic.columns[0].value);
    tableReference = tableReference.split('-').slice(0, 5).join('-');
    const databaseNode = applicationStore.executionResult?.response.find(node => node.data.nodeId === tableReference);
    if (databaseNode?.data?.meta?.pagination) {
      customPagination.value = true;
      paginationMetadata.value = {
        ...databaseNode.data.meta,
        databaseNodeId: databaseNode.data.nodeId
      };
    }
    await delay(500);
    columnFilters.value = headers.map(column => ({
      condition: {
        type: 'none',
        subType: '',
        value: ''
      },
      text: {
        keywords: []
      },
      columnId: column.label
    }));
    if (hasDefaultSorting) {
      if (defaultSortingColumns?.length && defaultSortingColumn === undefined) {
        const firstDefaultSortingColumn = defaultSortingColumns[0];
        if (!firstDefaultSortingColumn) return;
        sortingOrder = firstDefaultSortingColumn.direction;
        defaultSortingColumn = firstDefaultSortingColumn.label;
        if (!sortingOrder || !defaultSortingColumn) return;
      }
      const defaultSortingColumnInfo = headers.find(column => column.label === defaultSortingColumn);
      if (!defaultSortingColumnInfo) return;
      const sortOrder = sortingOrder === 'ascending' ? 'asc' : 'desc';
      handleSorting(defaultSortingColumnInfo, {
        sortOrder
      });
    }
    if (hasDefaultFiltering) {
      await handleFiltering(null, null, defaultFilteringColumns);
    }
  };
  onMounted(async () => {
    await defaultSortingAndFiltering();
  });
  return {
    sortedColumn,
    dataTableElement,
    columnFilters,
    customPagination,
    paginationMetadata,
    exportAsCsv,
    handleClick,
    tableData,
    totalRecords,
    getFilterIcon,
    handleFiltering,
    handleSorting,
    getColumnData,
    handlePageChange,
    getColumnKeyByLabel
  };
};
__sfc_main.components = Object.assign({
  TableOptionsDropdown,
  ColumnFiltersDropdown
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
@import '~@/style/utilities.scss';

.data-table {
    .table-wrapper {
        overflow: auto;
        min-height: 24rem
    }

    .table {
        .column-heading {
            white-space: nowrap;
            flex-grow: 1;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .th-wrap {
            >span {
                display: block;
                width: 100%;
            }
        }
    }
}
</style>
