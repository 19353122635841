import axios from 'axios';
import { parseExpression } from '@/helpers/ExpressionParser';

export default new class Base64Encoder {
    async parse(data, values) {
        const parsedExpression = parseExpression(data.data.url, values, 'strip');
        if (parsedExpression.value) {
            const response = await axios.get(parsedExpression.value, { responseType: 'arraybuffer' });
            parsedExpression.value = Buffer.from(response.data).toString('base64');
        } else {
            parsedExpression.value = '';
        }
        parsedExpression.isPlainText = true;
        return parsedExpression;
    }
};
