<template>
  <b-dropdown
    aria-role="list"
    class="table-options"
    position="is-bottom-left"
  >
    <template #trigger>
      <b-button
        type="is-primary"
        class="options-trigger"
        icon-right="dots-horizontal"
      />
    </template>
    <b-dropdown-item
      aria-role="listitem"
      @click="handleExporting()"
    >
      <b-icon 
        icon="file-excel-outline"
        size="is-small"
      />
      Export
    </b-dropdown-item>
  </b-dropdown>
</template>
  
<script >
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const handleExporting = () => {
    emit('export');
  };
  return {
    handleExporting
  };
};
export default __sfc_main;
</script>

<style lang="scss">
.table-options {
  .options-trigger {
    height: auto !important;
    width: auto;
    margin: 0;
  }
}
</style>
