import { extractReferenceId, parseExpression } from '@/helpers/ExpressionParser';
import { jsonBuilder } from '@/helpers/JsonBuilder';
import { parseArray, stringifyArray } from './formula-utils/arrayFormulas';

export default new class MapArray {
    parse (data, values) {
        const { arrayReference, indexReference, mappingItem } = data.data;

        // parse array
        const { value: rawArrayValue } = parseExpression(arrayReference, values, 'strip');
        const parsedArray = parseArray(rawArrayValue);

        // parse loop index ID
        const indexReferenceId = extractReferenceId(indexReference);

        const mappedArray = parsedArray.map((_, index) => {
            let mappedItem = null;
            values[indexReferenceId] = `${index}`;
            if (mappingItem.type === 'Object') {
                mappedItem = jsonBuilder.buildObject({}, mappingItem.value, values);
            } else {
                mappedItem = (parseExpression(mappingItem.value, values, 'strip')).value;
            }
            return mappedItem;
        });

        const processedValue = stringifyArray(mappedArray);
        return {
            value: processedValue,
            isPlainText: true
        };
    }
};
