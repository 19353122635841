<template>
  <b-dropdown
    ref="filtersDropdownElement"
    aria-role="list"
    class="column-filters"
  >
    <template #trigger>
      <b-icon
        :icon="filterIcon"
      />
    </template>
    <b-dropdown-item
      aria-role="listitem"
      custom
    >
      <template v-if="isSortable">
        <b-dropdown-item
          aria-role="listitem"
          class="is-flex is-justify-content-space-between is-align-items-center custom-dropdown-item"
          @click="handleSorting('asc')"
        >
          A → Z sortieren 
        </b-dropdown-item>
        <b-dropdown-item
          aria-role="listitem"
          class="is-flex is-justify-content-space-between is-align-items-center custom-dropdown-item"
          @click="handleSorting('desc')"
        >
          Z → A sortieren 
        </b-dropdown-item>
        <hr class="my-2">
      </template>
      <template v-if="isFilterable">
        <b-collapse
          v-model="isConditionSelectOpen"
          aria-id="contentIdForA11y2"
          class="custom-panel panel mb-0"
          animation="slide"
        >
          <template #trigger>
            <div
              role="button"
              aria-controls="contentIdForA11y2"
              class="pl-0 py-1 is-flex is-align-items-center custom-filter-accordian"
              :aria-expanded="isConditionSelectOpen"
            >
              <b-icon
                :icon="isConditionSelectOpen ? 'menu-down' : 'menu-right'"
                class="mr-1"
              /> Nach Bedingung filtern 
            </div>
          </template>
          <div class="pt-1 px-3 pb-2">
            <b-select
              v-model="columnFilters.condition.type"
              class="my-2"
              expanded
              size="is-small"
              @input="handleConditionUpdate($event)"
            >
              <option
                v-for="(choice, cIndex) in conditionOptions"
                :key="cIndex"
                :value="choice.value"
              >
                {{ choice.label }}
              </option>
            </b-select>
            <b-select
              v-if="columnFilters.condition.type === 'date'"
              v-model="columnFilters.condition.subType"
              class="date-conditions-dropdown"
              size="is-small"
              expanded
              @input="handleDateConditionUpdate"
            >
              <option
                v-for="(choice, cIndex) in dateConditions"
                :key="cIndex"
                :value="choice.value"
              >
                {{ choice.label }}
              </option>
            </b-select>
            <div
              v-if="columnFilters.condition.type !== 'none' && columnFilters.condition.subType"
              class="px-1 date-value-field"
            >
              <b-field
                label="Datum"
              >
                <BaseInput
                  v-model="columnFilters.condition.value"
                  size="is-small"
                  placeholder="2022-01-02"
                />
              </b-field>
            </div>
          </div>
        </b-collapse>
        <b-collapse
          v-model="isValueSelectOpen"
          aria-id="contentIdForA11y2"
          class="custom-panel panel mb-0"
          animation="slide"
        >
          <template #trigger>
            <div
              role="button"
              aria-controls="contentIdForA11y2"
              class="pl-0 py-1 is-flex is-align-items-center custom-filter-accordian"
              :aria-expanded="isValueSelectOpen"
            >
              <b-icon
                :icon="isValueSelectOpen ? 'menu-down' : 'menu-right'"
                class="mr-1"
              /> Nach Werten filtern
            </div>
          </template>
          <div class="p-1 px-3">
            <BaseInput
              v-model="filterKeyword"
              size="is-small"
              class="mt-2"
              type="text"
            />
            <div
              class="multi-select"
            >
              <div
                v-for="(option, index) in keywordOptions"
                :key="`option_${index}`"
                class="py-2 is-flex is-align-items-center is-justify-content-start"
                :class="columnFilters.text.keywords.includes(option) ? 'pl-0' : 'pl-5'"
                @click="onOptionSelect(option)"
              >
                <b-icon
                  v-if="columnFilters.text.keywords.includes(option)"
                  icon="check"
                  size="is-small"
                  class="mr-2 ml-0"
                  type="is-primary"
                /> {{ option }}
              </div>
            </div>
          </div>
        </b-collapse>
      </template>
    </b-dropdown-item>
    <hr class="my-2">
    <b-dropdown-item
      aria-role="listitem"
      custom
    >
      <div class="is-flex is-justify-content-space-between">
        <b-button 
          size="is-small"
          type="is-secondary"
          @click="clearFilter"
        >
          Filter löschen
        </b-button>
        <b-button 
          size="is-small"
          type="is-primary"
          @click="handleFiltering"
        >
          Ok
        </b-button>
      </div>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script >
import { debounce } from 'lodash';
import { ref, computed, onMounted, watch } from '@vue/composition-api';
import { cloneDeep } from 'lodash';
import BaseInput from '@/components/generics/BaseInput.vue';
import { fetchRecordsService } from '@/services/database-service/recordRequests';
const __sfc_main = {};
__sfc_main.props = {
  filterIcon: {
    type: String,
    default: 'filter-variant'
  },
  columnData: {
    type: Array,
    default: () => []
  },
  columnFilter: {
    type: Object,
    default: () => {
      return {
        condition: {
          type: 'none',
          subType: '',
          value: ''
        },
        text: {
          keywords: []
        }
      };
    }
  },
  columnKey: {
    type: String,
    default: ''
  },
  paginationMetadata: {
    type: Object,
    default: () => {}
  },
  customPagination: {
    type: Boolean,
    default: false
  },
  isFilterable: {
    type: Boolean,
    required: true
  },
  isSortable: {
    type: Boolean,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const conditionOptions = [{
    label: 'Keine',
    value: 'none'
  }, {
    label: 'Datum',
    value: 'date'
  }];
  const dateConditions = [{
    label: 'Datum liegt vor dem',
    value: 'before'
  }, {
    label: 'Datum liegt nach dem',
    value: 'after'
  }, {
    label: 'Datum ist der',
    value: 'exactly'
  }];
  const filterKeyword = ref('');
  const filtersDropdownElement = ref(null);
  const isConditionSelectOpen = ref(false);
  const isValueSelectOpen = ref(false);
  const records = ref([]);
  const columnFilters = ref({
    condition: {
      type: 'none',
      subType: '',
      value: ''
    },
    text: {
      keywords: []
    }
  });
  onMounted(() => {
    columnFilters.value = cloneDeep(props.columnFilter);
  });
  const keywordOptions = computed(() => {
    const hasBlanks = !records.value?.length && props.columnData.some(data => !data);
    let options = [];
    if (hasBlanks) {
      options.push('(Leer)');
    }
    options.push(...Array.from(new Set(records.value?.length ? records.value : props.columnData.filter(data => data))));
    return filterKeyword.value ? options.filter(option => option.toLowerCase().includes(filterKeyword.value.toLowerCase())) : options;
  });
  const handleSorting = sortOrder => {
    emit('sort', {
      sortOrder
    });
  };
  const handleFiltering = () => {
    emit('filter', cloneDeep(columnFilters.value));
    filtersDropdownElement.value.isActive = false;
  };
  const clearFilter = () => {
    columnFilters.value = {
      ...columnFilters.value,
      ...{
        condition: {
          type: '',
          subType: '',
          value: ''
        },
        text: {
          keywords: []
        }
      }
    };
    filterKeyword.value = '';
    handleFiltering();
  };
  const onOptionSelect = option => {
    const keywords = columnFilters.value.text.keywords;
    if (keywords.includes(option)) {
      keywords.splice(keywords.indexOf(option), 1);
    } else {
      keywords.push(option);
    }
  };
  const handleConditionUpdate = option => {
    columnFilters.value.condition.subType = option === '' ? null : 'before';
    columnFilters.value.condition.value = null;
  };
  const handleDateConditionUpdate = () => {
    columnFilters.value.condition.value = null;
  };
  const fetchRecords = debounce(async () => {
    try {
      let filter = {};
      if (filterKeyword.value) {
        filter.columns = {
          [props.columnKey]: filterKeyword.value
        };
      } else {
        filter = {};
      }
      const response = await fetchRecordsService({
        query: {
          database: props.paginationMetadata.query.database,
          table: props.paginationMetadata.query.table,
          condition: props.paginationMetadata.query.condition,
          filter,
          page: 1,
          limit: 20
        }
      });
      records.value = response.data.data?.map(obj => obj[props.columnKey]);
    } catch (err) {
      console.error(err);
    }
  }, 300);
  onMounted(async () => {
    if (props.customPagination) {
      await fetchRecords();
    }
  });
  watch(filterKeyword, async () => {
    await fetchRecords();
  });
  return {
    conditionOptions,
    dateConditions,
    filterKeyword,
    filtersDropdownElement,
    isConditionSelectOpen,
    isValueSelectOpen,
    columnFilters,
    keywordOptions,
    handleSorting,
    handleFiltering,
    clearFilter,
    onOptionSelect,
    handleConditionUpdate,
    handleDateConditionUpdate
  };
};
__sfc_main.components = Object.assign({
  BaseInput
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
.column-filters {
  .dropdown-menu {
    width: 300px;
    .input, .button {
      height: 2rem !important;
    }
  }
  .multi-select{
    height: 8.75rem; 
    overflow-y: auto;
    width: 100%;
    margin-top: 1rem;
    cursor: pointer;
    padding: 0rem;
    font-weight: 400;
  }
  .custom-dropdown-item{
    padding-left: 2.50rem;
  }
  .custom-panel{
    &.panel{
      box-shadow: none;
      font-size: inherit;
      background: inherit;
      padding: 2px 0;
    }
  }
  .date-value-field {
    margin-top: 0.5rem
  }
}
</style>
