import { parseExpression } from '../ExpressionParser';

export default new class  Upper {

    parse(data, values) {
        const parsedExpression = parseExpression(data.data.value, values, 'unwrap');
        parsedExpression.value = parsedExpression.value.toUpperCase();
        return parsedExpression;
    }
};
