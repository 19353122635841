import { parseExpression, stripHtml } from '@/helpers/ExpressionParser';

export default new class  MultipleCondition {

    parse(data, values) {
        const { value: switchValue } = parseExpression(data.data.value, values, 'strip');
        for (const option of data.data.options) {
            const optionCase = parseExpression(option.case, values, 'unwrap');
            const plainTextCaseValue = stripHtml(optionCase.value);
            const optionValue = parseExpression(option.value, values, 'unwrap');
            if (switchValue == plainTextCaseValue) {
                return optionValue;
            }
        }
        if (data.data.hasDefault) {
            const defaultValue = parseExpression(data.data.defaultValue, values, 'unwrap');
            return defaultValue;
        }

        return {
            value: '',
            isPlainText: true
        };
    }
};

