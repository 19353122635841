import { parseExpression } from '@/helpers/ExpressionParser';

export default new class CombineExpressions {
    parse(data, values) {
        const { expressions } = data.data;
        let result = false;
        for (const currentExpression of expressions) {
            const lazyParseExpression = () => parseExpression(currentExpression.value, values, 'strip');
            if (currentExpression.operator === 'AND') {
                result = result && lazyParseExpression().value;
            } else if (currentExpression.operator === 'OR') {
                result = result || lazyParseExpression().value;
            } else {
                result = lazyParseExpression().value;
            }
            result = !!result;
        }
        return {
            value: result,
            isPlainText: true
        };
    }
};
