import moment from 'moment-timezone';
import { parseExpression } from '@/helpers/ExpressionParser';

export default new class ManipulateDate {

    parse(data, values) {
        const { value, year, month,
            date, hour, minute, second, timezone } = data.data;

        const parsedExpression = parseExpression(value, values, 'skip');
        const parsedDate = moment.tz(parsedExpression.value, timezone);

        parsedDate.set({
            year: parseInt(year || parsedDate.year()),
            month: parseInt(month || parsedDate.month()),
            date: parseInt(date || parsedDate.date()),
            hour: parseInt(hour || parsedDate.hour()),
            minute: parseInt(minute || parsedDate.minute()),
            second: parseInt(second || parsedDate.second())
        });

        return {
            value: parsedDate.format(),
            isPlainText: true
        };
    }
};
