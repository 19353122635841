import * as cheerio from 'cheerio';
import IfCondition from './formula-parsers/IfCondition';
import MultipleCondition from './formula-parsers/MultipleCondition';
import ArithmeticCalculation from './formula-parsers/ArithmeticCalculation';
import Concatenate from './formula-parsers/Concatenate';
import Left from './formula-parsers/Left';
import Right from './formula-parsers/Right';
import Upper from './formula-parsers/Upper';
import Lower from './formula-parsers/Lower';
import Now from './formula-parsers/Now';
import TransformTimezone from './formula-parsers/TransformTimezone';
import DateArithmeticCalculation from './formula-parsers/DateArithmeticCalculation';
import ArrayFilter from './formula-parsers/ArrayFilter';
import Base64Encoder from './formula-parsers/Base64Encoder';
import NumberFormatter from './formula-parsers/NumberFormatter';
import ManipulateDate from './formula-parsers/ManipulateDate';
import AccessProperty from './formula-parsers/AccessProperty';
import MapArray from './formula-parsers/MapArray';
import DateFormatter from './formula-parsers/DateFormatter';
import InsertFormattedText from './formula-parsers/InsertFormattedText';
import CombineExpressions from './formula-parsers/CombineExpressions';
import SortArray from './formula-parsers/SortArray';
import GenerateFileLink from './formula-parsers/GenerateFileLink';
import ReduceArray from './formula-parsers/ReduceArray';
import SearchArray from './formula-parsers/SearchArray';

export default new class FormulaParser {
    parse(text, values) {
        const htmlDoc = cheerio.load(text || '');
        const body = htmlDoc.root().find('body');
        let formulas = body.find('.formula');

        let parsed_value = {
            value: text,
            isPlainText: true
        };
        for (let formula of formulas) {
            let excel_formula = cheerio.default(formula).data('formula');
            switch (excel_formula.formula) {
            case 'IF CONDITION IS TRUE':
                parsed_value = IfCondition.parse(excel_formula, values);
                break;
            case 'MULTIPLE CONDITION RESULT':
                parsed_value = MultipleCondition.parse(excel_formula, values);
                break;
            case 'ARITHMETIC CALCULATION':
                parsed_value = ArithmeticCalculation.parse(excel_formula, values);
                break;
            case 'CONCATENATE':
                parsed_value = Concatenate.parse(excel_formula, values);
                break;
            case 'LEFT':
                parsed_value = Left.parse(excel_formula, values);
                break;
            case 'RIGHT':
                parsed_value = Right.parse(excel_formula, values);
                break;
            case 'TO LOWER':
                parsed_value = Lower.parse(excel_formula, values);
                break;
            case 'TO UPPER':
                parsed_value = Upper.parse(excel_formula, values);
                break;
            case 'DATE ARITHMETICS':
                parsed_value = DateArithmeticCalculation.parse(excel_formula, values);
                break;
            case 'NOW':
                parsed_value = Now.parse(excel_formula, values);
                break;
            case 'CONVERT DATE TIMEZONE':
                parsed_value = TransformTimezone.parse(excel_formula, values);
                break;
            case 'FILTER THE ARRAY':
                parsed_value = ArrayFilter.parse(excel_formula, values);
                break;
            case 'FORMAT NUMBER':
                parsed_value = NumberFormatter.parse(excel_formula, values);
                break;
            case 'ENCODE TO BASE64':
                parsed_value = Base64Encoder.parse(excel_formula, values);
                break;
            case 'MANIPULATE DATE':
                parsed_value = ManipulateDate.parse(excel_formula, values);
                break;
            case 'ACCESS PROPERTY':
                parsed_value = AccessProperty.parse(excel_formula, values);
                break;
            case 'MAP ARRAY':
                parsed_value = MapArray.parse(excel_formula, values);
                break;
            case 'FORMAT DATE':
                parsed_value = DateFormatter.parse(excel_formula, values);
                break;
            case 'INSERT FORMATTED TEXT':
                parsed_value = InsertFormattedText.parse(excel_formula, values);
                break;
            case 'COMBINE EXPRESSIONS':
                parsed_value = CombineExpressions.parse(excel_formula, values);
                break;
            case 'SORT ARRAY':
                parsed_value = SortArray.parse(excel_formula, values);
                break;
            case 'GENERATE FILE LINK':
                parsed_value = GenerateFileLink.parse(excel_formula, values);
                break;
            case 'REDUCE ARRAY':
                parsed_value = ReduceArray.parse(excel_formula, values);
                break;
            case 'SEARCH ARRAY':
                parsed_value = SearchArray.parse(excel_formula, values);
                break;
            }
            let formulaToReplace = cheerio.default(formula).wrap('<span>').parent().html();
            const wrappedFormula = `<p>${formulaToReplace}</p>`;
            formulaToReplace = excel_formula.formula === 'INSERT FORMATTED TEXT' && text.includes(wrappedFormula) ? wrappedFormula : formulaToReplace;
            text = text.replace(formulaToReplace, parsed_value.value);
            parsed_value.value = text;
        }

        return parsed_value;
    }
};
