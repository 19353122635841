import { parseExpression } from '@/helpers/ExpressionParser';

export default new class  Concatenate {

    parse(data, values) {
        const parsedExpression = {
            value: '',
            isPlainText: true
        };
        const stringList = [];
        const parsedDelimiter = this.parseDelimiter(data.data.glue, data.data.customSeparator, values);
        for (const str of data.data.instances) {
            const parsedString = parseExpression(str.value, values, 'unwrap');
            stringList.push(parsedString.value);
            if (!parsedString.isPlainText) {
                parsedExpression.isPlainText = parsedString.isPlainText;
            }
        }
        parsedExpression.value = stringList.join(parsedDelimiter);
        return parsedExpression;
    }

    parseDelimiter(value, custom, values) {
        switch (value) {
        case 'Space':
            return ' ';
        case 'Comma':
            return ',';
        case 'Custom':
            return (parseExpression(custom, values, 'unwrap')).value;
        }
    }
};
