const FormulaParser = require('hot-formula-parser').Parser;
import { parseExpression } from '@/helpers/ExpressionParser';

export default new class ArithmeticCalculation {
    constructor() {
        this.formula_parser = new FormulaParser();
    }

    parse(data, values) {
        const parsedExpression = parseExpression(data.data.formula, values, 'strip');
        const validatedFormula = this.replaceInvalidValues(parsedExpression.value);
        let parsedFormula = this.formula_parser.parse(validatedFormula);
        const parsedValue = {
            value: '',
            isPlainText: parsedExpression.isPlainText
        };
        if (!parsedFormula.error) {
            parsedValue.value = parsedFormula.result;
        }
        return parsedValue;
    }

    replaceInvalidValues (expression) {
        const baseOperatorRegex = /([+\-*/])/;

        const leadingOperatorRegex = new RegExp(`^\\s*${baseOperatorRegex.source}\\s*`);
        const trailingOperatorRegex = new RegExp(`\\s*${baseOperatorRegex.source}\\s*$`);
        const invalidOperatorSequenceRegex = new RegExp(`${baseOperatorRegex.source}\\s+${baseOperatorRegex.source}`, 'g');

        // Replace leading '+' or '-' with '0 + ' or '0 - ' respectively
        expression = expression.replace(leadingOperatorRegex, '0 $1 ');
    
        // Replace trailing '+' or '-' with '0'
        expression = expression.replace(trailingOperatorRegex, ' $1 0');

        // Replace any invalid empty values between operators with '0'
        expression = expression.replace(invalidOperatorSequenceRegex, '$1 0 $2');
    
        return expression.trim();
    }
};
