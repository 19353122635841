import { parseExpression, stripHtml } from '@/helpers/ExpressionParser';

export default new class  Left {

    parse(data, values) {
        const parsedExpression = parseExpression(data.data.str.value, values, 'unwrap');
        const plainText = stripHtml(parsedExpression.value);
        const left = plainText.substring(0, parseInt(data.data.str.numOfCharacters));
        parsedExpression.value = parsedExpression.value.replace(plainText, left);
        return parsedExpression;
    }
};
