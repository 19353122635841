import * as cheerio from 'cheerio';
import { useApplicationStore } from '@/store/applicationStore';
import { pinia } from '@/plugins/pinia';
import { escapeHtml } from './Utils';

export default new class VariableParser {

    /**
     * @param {string} text
     * @param {object} values
     * @param {boolean} [shouldEscapeHtml]
     */
    parse(text, values, shouldEscapeHtml) {
        const htmlDoc = cheerio.load(text  || '');
        const body = htmlDoc.root().find('body');
        const elements = body.find('.mention');
        for (let element of elements) {
            let variable_id = cheerio.default(element).data('mention-id');
            let value = '';
            const applicationStore = useApplicationStore(pinia);
            if (values && values[variable_id] && !applicationStore.ignoredVariables[variable_id]) {
                value = values[variable_id];
                if (shouldEscapeHtml) {
                    value = escapeHtml(value);
                }
            }
            text = text.replace(cheerio.default(element).wrap('<span>').parent().html(), value);
            text = text.trim();
        }
        return text;
    }
};
