import { parseExpression, extractReferenceId } from '@/helpers/ExpressionParser';
import { parseArray, stringifyArray } from './formula-utils/arrayFormulas';

export default new class SearchArray {

    parse (data, values) {
        const { 
            arrayReference,
            searchCriteria,
            currentItemReference,
            indexReference
        } = data.data;
        // parse array
        const { value: rawArrayValue } = parseExpression(arrayReference, values, 'strip');
        const parsedArray = parseArray(rawArrayValue);

        // parse variable IDs
        const currentItemReferenceId = extractReferenceId(currentItemReference);
        const indexReferenceId = extractReferenceId(indexReference);

        const matchingValues = [];
        for (const index in parsedArray) {
            let currentItem = parsedArray[index];
            // update loop values
            if (indexReferenceId) {
                values[indexReferenceId] = index;
            }
            if (currentItemReferenceId) {
                if (currentItem) {
                    if (Array.isArray(currentItem)) {
                        currentItem = stringifyArray(currentItem);
                    }
                    else if (typeof currentItem === 'object') {
                        currentItem = JSON.stringify(currentItem);
                    }
                }
                values[currentItemReferenceId] = currentItem;
            }
            // search element
            const isMatchFound = (parseExpression(searchCriteria, values, 'strip')).value;
            if (isMatchFound) {
                matchingValues.push(parsedArray[index]);
            }
        }

        const processedValue = stringifyArray(matchingValues);

        return {
            value: processedValue,
            isPlainText: true
        };
    }
};
