import { parseExpression, stripHtml } from './ExpressionParser';

export const jsonBuilder = {
    buildObject(structuredObject, properties, values) {

        for (const property of properties) {
            if (property.type === 'Number') {
                let { value: numberProp } = parseExpression(property.value, values, 'skip');
                if (numberProp !== undefined && numberProp !== '<empty>') {
                    numberProp = parseFloat(stripHtml(numberProp));
                    structuredObject[property.name] = numberProp;
                }
            }

            if (property.type === 'String') {
                let { value: stringProp } = parseExpression(property.value, values, 'skip');

                if (stringProp !== undefined && stringProp !== '<empty>') {
                    stringProp = stripHtml(stringProp);
                    structuredObject[property.name] = stringProp;
                }
            }

            else if (property.type === 'Boolean') {
                let { value: booleanProp } = parseExpression(property.value, values, 'skip');

                if (booleanProp !== undefined && booleanProp !== '<empty>') {
                    booleanProp = stripHtml(booleanProp);
                    booleanProp = typeof booleanProp === 'string' ? booleanProp === 'true' : !!booleanProp;
                    structuredObject[property.name] = booleanProp;
                }
            }

            if (property.type === 'Object') {
                if (!structuredObject[property.name]) {
                    structuredObject[property.name] = {};
                }
                structuredObject[property.name] = this.buildObject(structuredObject[property.name], property.children, values);
            }

            if (property.type === 'Array') {
                if (!Array.isArray(structuredObject[property.name])) {
                    structuredObject[property.name] = [];
                }
                if (property.dynamic) {
                    let { value } = parseExpression(property.value, values, 'strip');
                    try {
                        JSON.parse(value);
                    } catch (e) {
                        value = `[${value.substring(1, value.length - 1)}]`;
                    }

                    structuredObject[property.name].push(JSON.parse(value));
                } else {
                    structuredObject[property.name] = this.buildArray(structuredObject[property.name], property.value, values);
                }
            }
        }

        return structuredObject;
    },
    buildArray(propArray, value, values) {
        for (const v of value) {
            if (v.type === 'Number') {
                let { value: numberProp } = parseExpression(v.value, values, 'strip');
                numberProp = parseFloat(numberProp);
                propArray.push(numberProp);
            }

            else if (v.type === 'String') {
                const { value: stringProp } = parseExpression(v.value, values, 'unwrap');
                propArray.push(stringProp);
            }

            else if (v.type === 'Boolean') {
                let { value: booleanProp } = parseExpression(v.value, values, 'strip');
                booleanProp = typeof booleanProp === 'string' ? booleanProp === 'true' : !!booleanProp;
                propArray.push(booleanProp);
            }

            else if (v.type === 'Object') {
                propArray.push(this.buildObject({}, v.children, value));
            }
        }
        return propArray;
    }
};
