import { parseExpression } from '@/helpers/ExpressionParser';

export default new class  Lower {

    parse(data, values) {
        const parsedExpression = parseExpression(data.data.value, values, 'unwrap');
        parsedExpression.value = parsedExpression.value.toLowerCase();
        return parsedExpression;
    }
};
