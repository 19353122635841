// @ts-check
import { authInterceptor, createHttpServiceConfig } from '@/services/httpFactories';
import { DATABASE_SERVICE_ENDPOINT, RECORD_RESOURCE_PATH } from './databaseServiceConstants';

const recordServiceConfig = createHttpServiceConfig(DATABASE_SERVICE_ENDPOINT, RECORD_RESOURCE_PATH);

//-- request interceptors --//
recordServiceConfig.addRequestInterceptor(authInterceptor);

/**
 * @param {import('./databaseServiceTypes').IFetchRecordsParams} params
 */
export const fetchRecordsService = (params) => recordServiceConfig.http({
    method: 'GET',
    params: {
        ...params,
        query: JSON.stringify(params.query)
    }
});
