import moment from 'moment-timezone';
import { parseExpression } from '@/helpers/ExpressionParser';

export default new class DateFormatter {
    parse(data, values) {
        const { inputDate, outputDate } = data.data;

        const { value: inputDateString } = parseExpression(inputDate.date, values, 'strip');
        let inputDateFormat = inputDate.format === 'Custom' ? (parseExpression(inputDate.customFormat, values, 'strip')).value : inputDate.format;
        if (inputDateFormat === 'Number') {
            inputDateFormat = 'x';
        }
        const parsedInputDate = moment(inputDateString, inputDateFormat);

        let outputDateFormat = inputDateFormat;
        if (outputDate.result === 'Date') {
            outputDateFormat = outputDate.format === 'Custom' ? (parseExpression(outputDate.customFormat, values, 'strip')).value : outputDate.format;
        } else if (outputDate.result === 'Number') {
            outputDateFormat = 'x';
        }

        return {
            value: parsedInputDate.format(outputDateFormat),
            isPlainText: true
        };
    }
};
