import { parseExpression, extractReferenceId } from '@/helpers/ExpressionParser';
import { jsonBuilder } from '@/helpers/JsonBuilder';
import { parseArray, stringifyArray } from './formula-utils/arrayFormulas';

export default new class ReduceArray {
    parse (data, values) {
        const { 
            arrayReference,
            resultReference,
            currentItemReference,
            indexReference,
            reducedItem
        } = data.data;
        // parse array
        const { value: rawArrayValue } = parseExpression(arrayReference, values, 'strip');
        const parsedArray = parseArray(rawArrayValue);

        // parse variable IDs
        const resultReferenceId = extractReferenceId(resultReference);
        const currentItemReferenceId = extractReferenceId(currentItemReference);
        const indexReferenceId = extractReferenceId(indexReference);

        let reducedValue = reducedItem.type === 'Number' ? 0 : '';
        if (currentItemReferenceId) {
            values[resultReferenceId] = reducedValue;
        }

        for (const index in parsedArray) {
            let currentItem = parsedArray[index];
            // update loop values
            if (indexReferenceId) {
                values[indexReferenceId] = index;
            }
            if (currentItemReferenceId) {
                if (currentItem) {
                    if (Array.isArray(currentItem)) {
                        currentItem = stringifyArray(currentItem);
                    }
                    else if (typeof currentItem === 'object') {
                        currentItem = JSON.stringify(currentItem);
                    }
                }
                values[currentItemReferenceId] = currentItem;
            }
            // compute result
            if (reducedItem.type === 'Object') {
                reducedValue = jsonBuilder.buildObject({}, reducedItem.value, values);
            } else {
                reducedValue = (parseExpression(reducedItem.value, values, 'strip')).value;
            }
            if (resultReferenceId) {
                values[resultReferenceId] = reducedValue;
            }
        }

        return {
            value: reducedValue,
            isPlainText: true
        };
    }
};
