import * as cheerio from 'cheerio';
import FormulaParser from './FormulaParser';
import VariableParser from './VariableParser';

/**
 * Parses raw expression to value in following ways (controlled via mode attribute):
 ** unwrap - removes only html wrapper element from parsed value
 ** strip - removes all nested html from the parsed value
 ** skip - no processing is performed on parsed value
 * @param {string} rawExpression 
 * @param {object} values 
 * @param {'unwrap' | 'strip' | 'skip'} [mode]
 */
export const parseExpression = (rawExpression, values, mode) => {
    if (!rawExpression) {
        return {
            value: rawExpression,
            isPlainText: true
        };
    }
    const parsedVariables = VariableParser.parse(rawExpression, values, [undefined, 'skip'].includes(mode));
    const parsedValue = FormulaParser.parse(parsedVariables, values);
    if (typeof parsedValue.value === 'string') {
        parsedValue.value = parsedValue.value.trim();
    }
    parsedValue.isPlainText = !!parsedValue.isPlainText;
    if (mode === 'unwrap') {
        parsedValue.value = unwrapExpression(parsedValue.value);
    } else if (mode === 'strip' || (mode === undefined && parsedValue.isPlainText)) {
        parsedValue.value = stripHtml(parsedValue.value);
    }
    return parsedValue;
};

/**
  * @param {string} parsedExpression 
  */
export const unwrapExpression = (parsedExpression) => {
    let formattedText = parsedExpression || '';
    if (parsedExpression && parsedExpression.startsWith('<p>') && parsedExpression.endsWith('</p>')) {
        formattedText = formattedText.substring(3, parsedExpression.length - 4);
    }
    formattedText = formattedText.trim();
    return formattedText;
};

/**
 * @param {string} parsedExpression 
 */
export const stripHtml = (parsedExpression) => {
    let formattedText = parsedExpression || '';
    formattedText = formattedText.replace(/<[^>]*>?/gm, '').trim();
    return formattedText;
};

/**
  * Parses formulas inside specified attribute of an HTML element 
  * @param {object} Tag
  * @param {string} raw_value
  * @param {object} values
*/
export const parseTagAttribute = (Tag, raw_value, values) => {
    if (raw_value) {
        const htmlDoc = cheerio.load(raw_value);
        const body = htmlDoc.root().find('body');
        const elements = body.find(`${Tag.selector}[${Tag.attribute}]`);
        for (const element of elements) {
            if (element.attribs[Tag.attribute] && element.attribs[Tag.attribute].startsWith('<p>')) {
                element.attribs[Tag.attribute] = parseExpression(element.attribs[Tag.attribute], values, 'strip').value;
            }
        }
        return body.html();
    }
    return raw_value;
};

/**
 * Extracts reference id of a variable from raw value
 * @param {string} raw_value 
 */
export const extractReferenceId = (raw_value) => {
    if (raw_value) {
        const htmlDoc = cheerio.load(raw_value);
        const body = htmlDoc.root().find('body');
        const elements = body.find('.mention');
        const referenceId = elements ? htmlDoc(elements[0]).data('mention-id') : null;
        return referenceId;
    }
    return null;
};


/**
 * Extracts reference id of a variable from raw value thats contains formula
 * @param {string} raw_value 
 */
export const extractReferenceIdFromFormula = (raw_value) => {
    const htmlString = raw_value?.replaceAll(/\\&quot;/g,'"');
    // Regular expression to match the data-mention-id attribute value
    const regex = /data-mention-id="([^"]+)"/;
    // Match the regular expression against the HTML string
    const match = htmlString.match(regex);
    // Extract the value of the data-mention-id attribute
    const dataMentionId = match ? match[1] : null;
    return dataMentionId;
};
