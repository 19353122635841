import { parseExpression } from '@/helpers/ExpressionParser';
import { parseArray, stringifyArray } from './formula-utils/arrayFormulas';

export default new class  SortArray {

    genericComparator(a, b, isAscending){
        let result = 0;
        if (a > b) {
            result = isAscending ? 1 : -1;
        } else  if (b > a) {
            result = isAscending ? -1 : 1;
        }
        return result;
    }


    sortArray(inputArray, isAscending, property) {
        if (!Array.isArray(inputArray)) {
            return inputArray;
        }

        const newArray = [...inputArray];

        newArray.sort((a, b) => {
            const aValue = property ? this.getPropertyValue(a, property) : a;
            const bValue = property ? this.getPropertyValue(b, property) : b;

            return this.genericComparator(aValue, bValue, isAscending);
        });

        return newArray;
    }

    getPropertyValue(obj, path) {
        const properties = path.split('.');
        return properties.reduce((acc, current) => acc[current], obj);
    }

    parse(data, values) {
        const { isAsc, sortByProperty, arrayReference, accessPath } = data.data;

        const { value: rawArrayValue } = parseExpression(arrayReference, values, 'strip');
        const parsedArray = parseArray(rawArrayValue);
        const property = parseExpression(accessPath, values, 'unwrap');
        const sortedArray = this.sortArray(parsedArray,isAsc,sortByProperty.value ? property.value : null);
        const processedValue = stringifyArray(sortedArray);
        return {
            value: processedValue,
            isPlainText: true
        };
    }

};
