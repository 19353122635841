const moment = require('moment-timezone');
import { parseExpression } from '@/helpers/ExpressionParser';

export default new class  TransformTimezone {

    parse(data, values) {
        let parsedValue = '';
        const { value } = parseExpression(data.data.date.value, values, 'strip');
        const first = moment.tz(value, data.data.date.fromTz).format();
        if (data.data.date.format === 'Custom') {
            const { value: customFormat } = parseExpression(data.data.date.customFormat, values, 'strip');
            parsedValue = moment.tz(first, data.data.date.toTz).format(customFormat);
        } else if (data.data.date.format === 'ISO Date') {
            parsedValue = moment.tz(first, data.data.date.toTz).format();
        } else {
            parsedValue = moment.tz(first, data.data.date.toTz).format(data.data.date.format);
        }
        return {
            value: parsedValue,
            isPlainText: true
        };
    }
};
